import { useTranslation } from "@i18n";
import stl from "./CardBody.module.css";
import { CDN_LINK } from "@globalConfig";
import { Link } from "react-router-dom";

const CardBody = ({
  post,
  showGrantedPopup,
  isCustomDesign,
  hideGrantedLabel,
  CustomTag,
  to_link
}) => {
  const { t } = useTranslation(["web_search"]);
  return (
    <Link to={to_link} className={stl.CardBody}>
      {!isCustomDesign && post.condition_id == "0" && !hideGrantedLabel ? (
        <div
          className={stl.guaranteedLabel}
          onClick={(e) => {
            e.preventDefault();
            e.stopPropagation();

            showGrantedPopup();
          }}
        >
          <img
            src={CDN_LINK + "/assets/images/garnteed.svg"}
            width={10}
            height={10}
            alt="garnteed"
            aria-label="garnteed"
          />
          <strong>{t("مفحوصة ومضمونة", { ns: "web_search" })}</strong>
        </div>
      ) : (
        ""
      )}

      <CustomTag className={`${stl.title} interActiveGray`}>
        {post.title}
      </CustomTag>

      <div className={stl.finance}>
        <div className={stl.monthlyPrice}>
          {parseInt(post.monthly_installment) > 0 && (
            <>
              <strong className="interActiveBlue">
                {post.monthly_installment}
              </strong>
              <span className="interActiveBlue">
                {t("ريال", { ns: "web_search" })} /{" "}
                {t("شهرياً", { ns: "web_search" })}
              </span>
            </>
          )}
        </div>
        <div className={stl.totalPrice}>
          <span className={`${stl.vatIncluded} interActiveGray`}>
            {t("شامل الضريبة", { ns: "web_search" })}
          </span>

          <div className={stl.sellingPrice}>
            <strong className="interActiveGreen">{post.sellingprice}</strong>
            <span className="interActiveGreen">
              {t("ريال", { ns: "web_search" })}
            </span>
          </div>

          {post.drop_price && (
            <div className={stl.oldPrice}>
              <strong className="interActiveGray">{post.drop_price}</strong>
              <span className="interActiveGray">
                {t("ريال", { ns: "web_search" })}
              </span>
            </div>
          )}
        </div>
      </div>
    </Link>
  );
};

export default CardBody;
