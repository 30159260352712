import { Link } from "react-router-dom";
import stl from "./CardFooter.module.css";

const CardFooter = ({ tags, isCustomDesign,to_link }) => {
  return (
    <Link to={to_link} className={`${stl.CardFooter} ${isCustomDesign ? "m-show" : ""}`}>
      {tags?.map((tag, i) => {
        const style = { backgroundColor: tag.color ? tag.color : "#f1f6fc" };

        return (
          <span style={style} className={stl.tag} key={i}>
            {tag.text}
          </span>
        );
      })}
    </Link>
  );
};

export default CardFooter;
